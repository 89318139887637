import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import config from '../../../config/config';
import configImages from '../../../config/configImages';

const FileGalleryView = ({ item, column, type }) => {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentFileName, setCurrentFileName] = useState('');

    const isVideo = (fileName) => {
        if (typeof fileName === 'string') {
            const ext = fileName?.split('.').pop().toLowerCase();
            return ['mp4', 'webm', 'ogg'].includes(ext);
        }
        return false;
    };
    const mediaFiles = item[column.field].map(file => {
        const fileName = typeof file === 'object' ? file.name : file;
        return {
            original: `${config.imgUrl}${type?.split(' ')?.join('')?.toLowerCase()}/${fileName}`,
            thumbnail: isVideo(fileName) ? configImages.vid : `${config.resolutionUrl}${type?.split(' ')?.join('')?.toLowerCase()}/md/${fileName}`,
            fileName: fileName,
            renderItem: () => (
                isVideo(fileName) ? (
                    <video controls className="gallery-media">
                        <source src={`${config.imgUrl}${type?.split(' ')?.join('')?.toLowerCase()}/${fileName}`} type={`video/${fileName?.split('.').pop().toLowerCase()}`} />
                        Your browser does not support the video tag.
                    </video>
                ) : (
                    <img src={`${config.resolutionUrl}${type?.split(' ')?.join('')?.toLowerCase()}/md/${fileName}`} alt={fileName} className="gallery-media" />
                )
            )
        };
    });


    const openGallery = () => {
        setViewerIsOpen(true);
        if (mediaFiles.length > 0) {
            setCurrentFileName(mediaFiles[0].fileName);
        }
    };

    const closeGallery = () => {
        setViewerIsOpen(false);
    };

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
        setCurrentFileName(mediaFiles[index].fileName);
    };

    return (
        <div className="FileGalleryView">
            {item[column.field]?.[0] && (
                <span onClick={openGallery}>
                    <img
                        src={`${config.resolutionUrl}${type?.split(' ')?.join('')?.toLowerCase()}/sm/${typeof item[column.field][0] === 'object' ? item[column.field][0].name : item[column.field][0]}`}
                        alt="thumbnail"
                    />
                </span>
            )}
            {viewerIsOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeGallery}>&times;</span>
                        <div className="gallery-index">{currentSlide + 1} / {mediaFiles.length}</div>
                        <ImageGallery
                            items={mediaFiles}
                            showPlayButton={true}
                            showFullscreenButton={true}
                            slideInterval={3000}
                            showIndex={true}
                            startIndex={currentSlide}
                            onSlide={handleSlideChange}
                        />
                        <div className="file-name-display">{currentFileName}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileGalleryView;

import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// config file

const Agents = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let user = localStorage.getItem('loginCredentials') ? JSON.parse(localStorage.getItem('loginCredentials')) : {};
  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Agents");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "FirstName",
        value: "",
        label: "FirstName",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "First Name",
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "LastName",
        value: "",
        label: "LastName",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Last Name",
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        fieldName: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Phone Number",
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        controllerName: null,
        textAlign: "left",
        show: false,
        required: true,
        displayInSettings: false,
        disabled: true,
        field: "password",
        header: "password",
        manuallyPushed: true,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        textAlign: "Center",
        show: false,
        disabled: true,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: false,
        fieldType: "dropDown",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "officeId",
        fieldName: "officeId",
        type: "relateAutoComplete",
        placeholder: "Office Id",
        value: "",
        label: "OfficeI d",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Office Id",
        derivedValue: "officeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "Office",
        searchApi: "offices",
        textAlign: "left",
        show: false,
        field: "officeId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "Office_name",
        type: "text",
        placeholder: "Office_name",
        label: "Office_name",
        header: "Office name",
        width: 110,
        parentField: "officeObjId",
        id: "Office_name",
        displayinlist: "false",
        fieldType: "RACSubField",
        textAlign: "left",
        subFieldName: "name",
        show: true,
        field: "Office_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Name",
        derivedValue: "name=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: false,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: false,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 25,
        stringType: null,
        header: "Status",
        derivedValue: "status=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Active",
            value: "Active",
            color: "primary",
            id: "act",
          },
          {
            label: "InActive",
            value: "Inactive",
            color: "secondary",
            id: "ina",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Active",
            value: "Active",
            color: "primary",
            id: "act",
          },
          {
            label: "InActive",
            value: "Inactive",
            color: "secondary",
            id: "ina",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
        customActionButton: customActionButton,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phoneNumber",
        fieldName: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        addFormOrder: 5,
        editFormOrder: 5,
        show: true,
        required: true,
        displayInSettings: false,
        disabled: true,
        manuallyPushed: true,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        controllerName: null,
        addFormOrder: 6,
        editFormOrder: 6,
        options: [],
        show: true,
        disabled: true,
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "officeId",
        fieldName: "officeId",
        type: "relateAutoComplete",
        placeholder: "Office Id",
        value: "",
        label: "OfficeI d",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "officeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "Office",
        searchApi: "offices",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "name=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "FirstName",
        value: "",
        label: "FirstName",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "FirstName",
        derivedValue: "firstName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "LastName",
        value: "",
        label: "LastName",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "LastName",
        derivedValue: "lastName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        fieldName: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Phone Number",
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "password",
        type: "password",
        placeholder: "password",
        label: "password",
        id: "password",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        textAlign: "left",
        show: true,
        required: true,
        displayInSettings: false,
        disabled: true,
        field: "password",
        header: "password",
        manuallyPushed: true,
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "role",
        type: "dropDown",
        placeholder: "Role",
        label: "Role",
        header: "Role",
        derivedValue: "role=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "role",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        options: [],
        textAlign: "Center",
        show: true,
        disabled: true,
        field: "role",
        mobile: true,
        globalSearchField: "true",
        required: true,
        displayInSettings: true,
        fieldType: "dropDown",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "officeId",
        fieldName: "officeId",
        type: "relateAutoComplete",
        placeholder: "Office Id",
        value: "",
        label: "OfficeI d",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "OfficeI d",
        derivedValue: "officeId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "Office",
        searchApi: "offices",
        textAlign: "left",
        show: true,
        field: "officeId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Office_name",
        type: "text",
        placeholder: "Office_name",
        label: "Office_name",
        header: "Office name",
        width: 110,
        parentField: "officeId",
        id: "Office_name",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "left",
        subFieldName: "name",
        show: true,
        field: "Office_name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Name",
        derivedValue: "name=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.agents}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];


  const customActionButton = (item, openConfirmationModal, openFormModal, setDeleteRecords, dirty) => {
    let adminAgent = (user.type && user.type !== 'Agent' && user._id !== item._id);
    
    return (
      <>
        {user.type && user.type !== 'Agent' ? <FontAwesomeIcon
          className='genderIconAlignment'
          color='white'
          icon='edit'
          data-toggle="tool-tip" title="Edit"
          style={{ color: '#024a88', width: '15', cursor: 'pointer' }}
          onClick={() => openFormModal(item, 'edit', dirty)} /> : ''}
        {adminAgent ?
          <FontAwesomeIcon
            className='genderIconAlignment'
            color='white'
            icon='trash-alt'
            data-toggle="tool-tip"
            title="Delete"
            style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
            onClick={() => setDeleteRecords(item, "Delete")} />
          : null}
        {item.status == 'Active' && adminAgent ?
          <FontAwesomeIcon
            className='genderIconAlignment'
            color='white'
            icon='ban'
            data-toggle="tool-tip"
            title="InActive"
            style={{ color: '#024a88', width: '15', marginLeft: 10, cursor: 'pointer' }}
            onClick={() => openConfirmationModal(item, 'Block the Agent')}
          />
          : adminAgent ?
            <FontAwesomeIcon
              className='genderIconAlignment'
              color='white'
              icon='check-circle'
              data-toggle="tool-tip"
              title="Active"
              style={{ color: 'blue', width: '15', marginLeft: 10, cursor: 'pointer' }}
              onClick={() => openConfirmationModal(item, 'UnBlock the Agent')}
            /> : null}
      </>
    )
  }

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={config.buttonRequired}
          sample={config.buttonRequired}
          printRequried={config.buttonRequired}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.agents}
          globalSearch={"firstName/lastName/email/role/officeId/name"}
          displayName="Agents"
          type="Agents"
          routeTo={apiCalls.agents}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.agents?.toLowerCase()}
          apiUrl={apiCalls.agents}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          filterClearRequired={true}
          hideActions={true}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="agents"
          apiUrl={apiCalls.agents}
        />
      ) : null}
    </span>
  );
};

export default Agents;

import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Clients = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Clients");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "photo",
        fieldName: "photo",
        type: "fileUpload",
        placeholder: "Photo",
        value: "",
        label: "Photo",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 6,
        stringType: null,
        header: "Photo",
        derivedValue: "photo=photo=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "photo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 3,
        isMultipleRequired: false,
        imagePath: "Clients",
        textAlign: "center",
        show: true,
        field: "photo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 1,
        stringType: null,
        header: "First Name",
        derivedValue: "firstName=firstName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 2,
        stringType: null,
        header: "Last Name",
        derivedValue: "lastName=lastName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 3,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        fieldName: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Phone Number",
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "checkbox",
        placeholder: "Type",
        value: false,
        label: "Type",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Type",
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "type",
        fieldType: "checkbox",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 5,
        stringType: null,
        header: "Notes",
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "notes",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 9,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        derivedValue: "firstName=firstName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "lastName=lastName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phoneNumber",
        fieldName: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "type",
        fieldName: "type",
        type: "checkbox",
        placeholder: "Type",
        value: ['Seller'],
        label: "Type",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        options: [
          { label: 'Buyer', value: 'Buyer' },
          { label: 'Seller', value: 'Seller' },
        ],
        multiple:true,
        multipleSelect: false,
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "photo",
        fieldName: "photo",
        type: "fileUpload",
        placeholder: "Photo",
        value: "",
        label: "Photo",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        derivedValue: "photo=photo=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "photo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 1,
        isMultipleRequired: false,
        imagePath: "Clients",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "name=name=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 9,
        editFormOrder: 10,
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "firstName",
        fieldName: "firstName",
        type: "text",
        placeholder: "First Name",
        value: "",
        label: "First Name",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 1,
        stringType: null,
        header: "First Name",
        derivedValue: "firstName=firstName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "firstName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "lastName",
        fieldName: "lastName",
        type: "text",
        placeholder: "Last Name",
        value: "",
        label: "Last Name",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 2,
        stringType: null,
        header: "Last Name",
        derivedValue: "lastName=lastName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "lastName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        fieldName: "email",
        type: "email",
        placeholder: "Email",
        value: "",
        label: "Email",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 3,
        stringType: null,
        header: "Email",
        derivedValue: "email=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "email",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phoneNumber",
        fieldName: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 4,
        stringType: null,
        header: "Phone Number",
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "phoneNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "checkbox",
        placeholder: "Type",
        value: false,
        label: "Type",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        header: "Type",
        derivedValue: "type=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 5,
        stringType: null,
        header: "Notes",
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "notes",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "photo",
        fieldName: "photo",
        type: "fileUpload",
        placeholder: "Photo",
        value: "",
        label: "Photo",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 6,
        stringType: null,
        header: "Photo",
        derivedValue: "photo=photo=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "photo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 1,
        isMultipleRequired: false,
        imagePath: "Clients",
        textAlign: "center",
        show: false,
        field: "photo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 10,
        header: "Name",
        derivedValue: "name=name=name=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "name",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 9,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: false,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "false",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: config.dateFormat,
        fieldType: "Date",
        textAlign: "center",
        show: false,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.clients}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={config.buttonRequired}
          sample={config.buttonRequired}
          printRequried={config.buttonRequired}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.clients}
          globalSearch={
            "firstName/lastName/email/phoneNumber/type/notes/photo/name"
          }
          displayName="Clients"
          type="Clients"
          routeTo={apiCalls.clients}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.clients?.toLowerCase()}
          apiUrl={apiCalls.clients}
          selectedId={params.id}
          displayActionsInTable={"actions"}
          filterClearRequired={true}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="clients"
          apiUrl={apiCalls.clients}
        />
      ) : null}
    </span>
  );
};

export default Clients;
